var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Address")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:500",
                    expression: "'max:500'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: { name: "Address", rows: "3" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Address"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Contact Details")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:500",
                    expression: "'max:500'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Contact Details" },
                model: {
                  value: _vm.form.contactDetails,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contactDetails", $$v)
                  },
                  expression: "form.contactDetails",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Contact Details"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Opening Times")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:500",
                    expression: "'max:500'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Opening Times" },
                model: {
                  value: _vm.form.openingTimes,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "openingTimes", $$v)
                  },
                  expression: "form.openingTimes",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Opening Times"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Active")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.isActive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isActive", $$v)
                  },
                  expression: "form.isActive",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }